@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"

:root
  @supports (-webkit-overflow-scrolling: touch)
    :where(input)
      font-size: 1rem

html, body
  -webkit-overflow-scrolling: touch !important

@layer base
  .display
    @apply font-medium font-display text-cool-800

  .display-6xl
    @extend .display
    @apply text-6xl tracking-custom

  .display-5xl
    @extend .display
    @apply text-5xl tracking-custom

  .display-4xl
    @extend .display
    @apply text-4xl tracking-custom

  .display-3xl
    @extend .display
    @apply text-3xl tracking-custom

  .display-2xl
    @extend .display
    @apply text-2xl tracking-custom

  h1
    @extend .display
    @apply text-6xl

  h2
    @extend .display
    @apply text-5xl

  h3
    @extend .display
    @apply text-4xl

  h4
    @extend .display
    @apply text-3xl

  h5
    @extend .display
    @apply text-2xl

  h6
    @extend .display
    @apply text-lg

  body
    @apply text-base font-body text-cool-900 bg-cool-50

  .p-container
    @apply px-[1.5rem] md:px-[3rem] lg:px-[3rem] 2xl:px-[calc(50%_-_theme(screens.2xl)_/_2_+_3rem)]

  // Hide scrollbar for IE, Edge and Firefox
  .no-scrollbar
    -ms-overflow-style: none
    scrollbar-width: none

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar
      display: none

input
  appearance: none !important
  -webkit-appearance: none !important
  -moz-appearance: none !important

  // &[type="search"]
  //   appearance: textfield !important

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration
    appearance: none !important

  &[type="checkbox"]
    @apply relative appearance-none w-5 h-5 p-0 block cursor-pointer animate-none border-2 border-cool-400

    &:checked
      background:
        image: url(/files/icons/custom/check.svg)
        size: 14px
        repeat: no-repeat
        position: center
      @apply bg-cool-900 border-cool-900

  &[type="radio"]
    @apply relative appearance-none w-5 h-5 p-0 block cursor-pointer animate-none border-2 border-cool-400 rounded-full

    &:checked
      @apply bg-cool-50 border-cool-900 border-[6px]

  // Hide eye on IE and Edge browsers
  &::-ms-reveal,
  &::-ms-clear
    display: none

.slider
  -webkit-appearance: none
  width: 100%
  height: 1px
  border-radius: 5px
  background: #fff
  outline: none
  cursor: pointer

.slider::-webkit-slider-thumb
  -webkit-appearance: none
  appearance: none
  width: 20px
  height: 20px
  border-radius: 50%
  background: #fff
  cursor: pointer

.slider::-moz-range-thumb
  width: 20px
  height: 20px
  border-radius: 50%
  background: #fff
  cursor: pointer

div.crown-icon
  div
    div.icon.card-icon
      svg
        path
          color: #fff

.custom-answer
  ul
    list-style: inside
  ol
    list-style: decimal
    list-style-position: inside
  blockquote
    font-style: italic
    background: #f9f9f9
    border-left: 10px solid #ccc
    margin: 1.5em 10px
    padding: 0.5em 10px
    quotes: "\201C""\201D""\2018""\2019"
    &::before
      color: #ccc
      content: open-quote
      font-size: 4em
      line-height: 0.1em
      margin-right: 0.25em
      vertical-align: -0.4em
    p
      display: inline

  a
    color: rgb(0, 112, 153)
    text-decoration: underline

.verification-failed
  flex-direction: column
  @media screen and (min-width: 375px)
    flex-direction: row

section
  scroll-margin-top: 5rem
  @media screen and (max-width: 1023px)
    scroll-margin-top: 4rem

[data-radix-popper-content-wrapper]
  min-width: fit-content !important
  width: auto !important

[data-side="top"], [data-side="bottom"]
  @media screen and (max-width: 450px)
    .c-ihuKFO
      margin-left: 20px !important

.search-results
  @media screen and (min-width: 640px)
    height: fit-content!important
    height: -webkit-fit-content!important
    height: -moz-fit-content!important

.no-translate
  -webkit-transform: translate3d(0, 0, 0)

div[data-radix-popper-content-wrapper]
  outline: none !important

.drawer
  &__container
    animation-duration: 350ms

    &-left
      &-open
        animation-name: drawer-left-open
        @apply translate-x-0

      &-closed
        @apply -translate-x-[350px] transition-transform duration-[350ms] ease-in-out

    &-right
      &-open
        animation-name: drawer-right-open
        @apply translate-x-0

      &-closed
        @apply translate-x-[350px] transition-transform duration-[350ms] ease-in-out

  &__backdrop
    @apply transition-opacity duration-[350ms] ease-in-out will-change-[opacity]

    &-open
      @apply opacity-100

    &-closed
      @apply opacity-0

@keyframes drawer-left-open
  from
    transform: translateX(-350px)
  to
    transform: translateX(0)

@keyframes drawer-right-open
  from
    transform: translateX(350px)

  to
    transform: translateX(0)

.Toastify__toast-icon
  margin-right: 1rem
  max-width: 1.5rem
  width: auto

.break-word
  word-break: break-word

@media (max-width: 640px)
  div[class^="termly-styles-root"]
    display: none
    padding: 2em !important
    padding-bottom: 0 !important

  div[class^="termly-styles-message"]
    margin-bottom: 1.75em !important

  div[class^="termly-styles-buttons"]
    padding: 2em 0 !important
