@keyframes overlay-enter
  from
    opacity: 0
  to
    opacity: 1

@keyframes overlay-leave
  from
    opacity: 1
  to
    opacity: 0

.overlay
  animation-duration: 500ms
  z-index: 99999

  &-open
    pointer-events: auto
    opacity: 1
    animation-name: overlay-enter

  &-closed
    pointer-events: none
    opacity: 0
    animation-name: overlay-leave
